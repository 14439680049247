import axios from "axios"
import Helper from "@/plugins/helper"
import FormData from "form-data";
import { TokenIDCookieName } from "@/plugins/constant"

export default class GraphQlClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async callGraphQL(body = {}) {
    var res = await axios.post(this.baseUrl, body, {
      headers: {
        Authorization: Helper.getLocalStorage(TokenIDCookieName)
      }
    });
    var { data } = res
    if (data && data.errors && data.errors.length) {
      throw new Error(data.errors[0].message)
    }
    return data.data
  }

  async post(query = "") {
    query = query.replace(/\\n/g, '').replace(/\\t/g, '')
    let body = { query }
    return await this.callGraphQL(body)
  }

  async postFile(file, queryString = "", variablesObj = {}) {
    variablesObj = { ...variablesObj, file: null }
    var o = JSON.stringify({ query: queryString, variables: variablesObj })
    o = o.replace(/\\n/g, '').replace(/\\t/g, '')
    let map = `{"0": ["variables.file"]}`
    let fd = new FormData()
    fd.append('operations', o)
    fd.append('map', map)
    fd.append(0, file)
    return await this.callGraphQL(fd);
  }
}