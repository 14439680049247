<template>
  <div class="d-block" style="width: 200px">
    <span
      class="d-block text-center red--text"
      v-if="
        analysis.statusID == AnalysisStatusType.Failed ||
        analysis.statusID == AnalysisStatusType.Deleted
      "
      >{{
        analysisStatusOptions.find((o) => o.value === analysis.statusID).text
      }}</span
    >
    <span v-else>
      <span v-if="analysis.statusID == AnalysisStatusType.Success">
        <v-btn small @click="onViewResult()">
          <v-icon small class="mr-2">mdi-eye-outline</v-icon> View result
        </v-btn>
      </span>
      <v-progress-linear
        v-else
        color="light-blue"
        height="5"
        :value="analysis.percent || 5"
        stream
        buffer-value="0"
      ></v-progress-linear>
    </span>
  </div>
</template>

<script>
import { AnalysisStatusType } from "@/plugins/constant";
import { getTaskDetail } from "../../services/task";
import { sleep } from "@/plugins/helper";
import AnalysisService from "../../services/analysis";

export default {
  props: {
    analysis: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      AnalysisStatusType,
      ProgressTimeout: null,
    };
  },
  computed: {
    analysisStatusOptions() {
      return Object.keys(AnalysisStatusType).map((key) => ({
        value: AnalysisStatusType[key],
        text: key,
      }));
    },
  },
  mounted() {
    this.transformItem();
  },
  methods: {
    onViewResult() {
      this.$emit("onViewResult", this.analysis.analysisID);
    },
    isFinisedTaskStatus(statusID) {
      return [
        AnalysisStatusType.Success,
        AnalysisStatusType.Failed,
        AnalysisStatusType.Deleted,
      ].includes(statusID);
    },
    updateProgress(taskID) {
      // After some delay, run a function to update progress
      this.ProgressTimeout = setTimeout(async () => {
        // 1. Clear the current timeout
        clearTimeout(this.ProgressTimeout);
        this.ProgressTimeout = null;

        // 2. Get progress detail
        var result = await getTaskDetail(taskID);
        // If error, then expire the whole function
        if (!result || result.error) {
          return;
        }
        // if not, proceed to get data and update progress
        var { statusID, jsonResults, percent } = result;
        var { analysisID } = this.analysis;

        this.analysis.percent = percent;
        this.analysis.statusID = statusID;
        this.analysis.jsonResults = jsonResults;
        this.$emit("onProgressUpdate", result);

        this.$forceUpdate();
        await sleep(Math.floor(Math.random() * 2000));
        // after wait sometime, check if the progress is finised
        if (this.isFinisedTaskStatus(statusID)) {
          // if yes, then update status and result of the analysis
          var body = {
            statusID,
            jsonResults,
          };
          await AnalysisService.updateAnalysis(analysisID, body);
          // and stop the function
          return;
        }
        // Else, then call the function again
        this.updateProgress(taskID);
      }, Math.floor(Math.random() * 10000));
    },
    async transformItem() {
      var { taskID, statusID } = this.analysis;
      if (this.isFinisedTaskStatus(statusID)) {
        return;
      }
      this.updateProgress(taskID);
    },
  },
};
</script>