<template>
  <v-select
    :label="$t('analysis.AnalysisMethods')"
    :items="options"
    v-model="selection"
    outlined
    dense
    @change="onSelection"
  ></v-select>
</template>

<script>
import { getAllFlows } from "../../services/task";

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      options: [],
      selection: null,
      flows: [],
    };
  },
  watch: {
    value() {
      this.selection = this.value;
    },
  },
  async created() {
    this.getAnalysisOptions();
  },
  methods: {
    onSelection() {
      this.$emit("input", this.selection);
    },
    async getAnalysisOptions() {
      var result = await getAllFlows();
      if (!result || result.error) return;
      this.options = result.map((i) => ({
        text: i.title,
        value: i.title,
      }));
      this.flows = result;
    },
  },
};
</script>