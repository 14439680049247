<template>
  <v-select
    :label="$t('analysis.StatusOptions')"
    :items="analysisStatusOptions"
    v-model="statusID"
    outlined
    dense
    @change="onSelection"
  ></v-select>
</template>

<script>
import { AnalysisStatusType } from "@/plugins/constant";

export default {
  props: ["value"],
  data() {
    return {
      statusID: this.value || AnalysisStatusType.All,
    };
  },
  watch: {
    value() {
      this.statusID = this.value;
    },
  },
  computed: {
    analysisStatusOptions() {
      return Object.keys(AnalysisStatusType).map((key) => ({
        value: AnalysisStatusType[key],
        text: key,
      }));
    },
  },
  methods: {
    onSelection() {
      this.$emit("input", this.statusID);
    },
  },
};
</script>