<template>
  <v-container
    class="grey lighten-3 pb-6"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-1': dragover }"
  >
    <v-file-input
      id="dropzone-file-input"
      style="display: none"
      @change="handleFileInputs()"
      v-model="inputFiles"
      multiple
      truncate-length="15"
    ></v-file-input>
    <v-row class="d-flex flex-column" dense align="center" justify="center">
      <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
        mdi-cloud-upload
      </v-icon>
      <a @click="triggerInputFiles" class="mb-4">
        Drop your file(s) here, or click to select them.
      </a>
    </v-row>
    <v-virtual-scroll
      v-if="uploadedFiles.length > 0"
      :items="uploadedFiles"
      :height="
        uploadedFiles.length * 50 > 150 ? 150 : uploadedFiles.length * 50
      "
      item-height="50"
    >
      <template v-slot:default="{ item }">
        <v-list-item :key="item.name">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
              <span class="ml-3 text--secondary"> {{ item.size }} bytes</span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn @click.stop="removeFile(item.name)" icon>
              <v-icon> mdi-close-circle </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
      </template>
    </v-virtual-scroll>
    <span
      class="d-block text-center red--text"
      v-html="errorMessage"
      v-if="errorMessage"
    ></span>
  </v-container>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    extensions: {
      type: Array,
      default: null,
    },
  },
  watch: {
    value() {
      this.uploadedFiles = this.value || [];
    },
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
      errorMessage: "",
      inputFiles: [],
    };
  },
  methods: {
    clean() {
      this.dragover = false;
      this.uploadedFiles = [];
      this.inputFiles = [];
      this.errorMessage = "";
    },
    triggerInputFiles() {
      document.querySelector("#dropzone-file-input").click();
    },
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
      this.submit();
    },
    onDrop(e) {
      this.dragover = false;
      this.errorMessage = "";
      // If there are already uploaded files remove them
      if (!this.multiple) {
        this.uploadedFiles = [];
      }
      e.dataTransfer.files.forEach((element) => {
        this.collectFile(element);
      });
      this.submit();
    },
    handleFileInputs() {
      this.inputFiles.forEach((element) => {
        this.collectFile(element);
      });
      this.submit();
      this.inputFiles = [];
    },
    collectFile(element) {
      if (this.extensions && this.extensions.length) {
        var ext = element.name.split(".")[1];
        if (!ext) {
          this.errorMessage += `File ${element.name} has no extension. <br/>`;
          return;
        }
        ext = ext.toLowerCase();
        if (!this.extensions.includes(ext)) {
          this.errorMessage += `File ${
            element.name
          } not supported. Allows: ${this.extensions.join(", ")} <br/>`;
          return;
        }
      }
      this.uploadedFiles.push(element);
    },
    submit() {
      this.$emit("input", this.uploadedFiles);
    },
  },
};
</script>