import { ApiGateway } from "@/plugins/gateway"
import GraphQlClient from "@/plugins/graphqlClient"
import HttpClient from '@/plugins/httpClient'

var baseUrl = ApiGateway.AiFunctions
var graphQlClient = new GraphQlClient(baseUrl)
var flowClient = new HttpClient(`${ApiGateway.Emr}/Flow`);

export const getTaskDetail = async (taskID) => {
  var query = `
  query {
    getTaskDetail(taskID: "${taskID}") {
      taskID
      jsonResults
      jsonSchema
      percent
      statusID
    }
  }`
  let res = await graphQlClient.post(query)
  if (res.error) return res
  return res.getTaskDetail
}

export const getStreamUrl = (url) => {
  return `${baseUrl.replace("graphql", "stream")}?filePath=${url}`
}

export const searchFunctions = async (name = "", page = 1, limit = 10000) => {
  var query = `
  query {
    searchFunctions(name: "${name}", page: ${page}, limit: ${limit}) {
      name
      originalName
      title
      family
      description
      fields
      allowAddFields
    }
  }`
  let res = await graphQlClient.post(query)
  if (res.error) return res
  return res.searchFunctions
}

export const getAllFlowsV1 = async () => {
  var query = `
  query {
    searchFlows(page: 1, limit: 1000) {
      title
    }
  }`
  let res = await graphQlClient.post(query)
  if (res.error) return res
  return res.searchFlows
}
export const getAllFlows = async () => {
  var result = await flowClient.get('/Search');
  if (!result || result.error) return []
  return result.items
}

export const executeFlow = async (file, title = "Dry Eye - Video Analysis") => {
  var query = `
      mutation ($title: String, $file: Upload!) {
        executeFlow(title: $title, file: $file) { 
          taskID,
          jsonResults
        }
      }`
  var variables = {
    title
  }
  let res = await graphQlClient.postFile(file, query, variables);
  if (res.error) return res
  return res.executeFlow
}

export const executeSchema = async (file, schema = {}) => {
  var query = `
      mutation ($jsonSchema: String!, $file: Upload!) {
        executeSchema(jsonSchema: $jsonSchema, file: $file) { 
          taskID,
          jsonResults
        }
      }`
  var variables = {
    jsonSchema: JSON.stringify(schema),
  }
  let res = await graphQlClient.postFile(file, query, variables);
  if (res.error) return res
  return res.executeSchema
}