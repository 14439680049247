import HttpClient from '@/plugins/httpClient'
import { ApiGateway } from '@/plugins/gateway'

var baseUrl = `${ApiGateway.Emr}/Analysis`
var httpClient = new HttpClient(baseUrl);

export default {
  searchAnalysis(analysisName = "", patientID = "", statusID = "", page = 1, limit = 10) {
    var query = {
      analysisName,
      patientID,
      statusID,
    }
    var headers = {
      page, limit
    }
    return httpClient.get('/Search', query, headers);
  },
  getAnalysisDetail(analysisID) {
    return httpClient.get(`/Get/${analysisID}`);
  },
  createAnalysis({ analysisName, taskID, patientID, episodeID, episodeRecordID, orderID, jsonResults }) {
    var data = { analysisName, taskID, patientID, episodeID, episodeRecordID, orderID, jsonResults }
    return httpClient.post(`/Create`, {}, data);
  },
  updateAnalysis(analysisID, { statusID, jsonResults }) {
    var data = { statusID, jsonResults }
    return httpClient.put(`/Update/${analysisID}`, {}, data);
  },
  deleteAnalysis(analysisID) {
    return httpClient.put(`/Delete/${analysisID}`);
  }
}