var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPlaintext && _vm.floatingMetadata)?_c('span',{staticClass:"d-block analysis-plaintext-floating"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.metadataText)}})]):_vm._e(),(_vm.isPlaintext && !_vm.floatingMetadata)?_c('span',{staticClass:"d-block analysis-plaintext-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s(_vm.metadataText)}},'span',attrs,false),on))]}}],null,false,688088077)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.metadataText)}})])],1):_vm._e(),(_vm.isHeatmap)?_c('span',{staticClass:"d-block analysis-heatmap-container"},_vm._l((_vm.numBlocks),function(i){return _c('v-tooltip',{key:i,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"block",class:{
            'grey lighten-2': _vm.series[i - 1] == 0,
            'amber lighten-2': _vm.series[i - 1] == 1,
            'orange lighten-2': _vm.series[i - 1] == 2,
            'grey lighten-2': !_vm.series[i - 1],
          },style:({
            width: ("calc(" + (100 / _vm.numBlocks) + "%)"),
          }),on:{"click":function($event){return _vm.onBlockClick(i)}}},'div',attrs,false),on))]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.className[_vm.series[i - 1]])}})])}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }