var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('v-row',{staticClass:"pl-2 pr-2 pt-2"},[_c('v-col',{attrs:{"cols":"","sm":"6","md":"4"}},[_c('AnalysisMethodOptions',{on:{"input":_vm.searchListAnalysis},model:{value:(_vm.analysisName),callback:function ($$v) {_vm.analysisName=$$v},expression:"analysisName"}})],1),_c('v-col',{attrs:{"cols":"","sm":"6","md":"2"}},[_c('AnalysisStatusOptions',{on:{"input":_vm.searchListAnalysis},model:{value:(_vm.statusID),callback:function ($$v) {_vm.statusID=$$v},expression:"statusID"}})],1),_c('v-col',{attrs:{"cols":""}},[_c('AnalysisPatientOptions',{on:{"input":_vm.searchListAnalysis},model:{value:(_vm.patientID),callback:function ($$v) {_vm.patientID=$$v},expression:"patientID"}})],1),_c('v-col',{attrs:{"cols":"","sm":"2","md":"1"}},[_c('v-btn',{staticStyle:{"margin":"2px 0px 0px -10px","width":"100%"},attrs:{"color":"primary"},on:{"click":_vm.openPopupCreateAnalysis}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("flow.CreateBtn"))+" ")],1)],1)],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.isSkeletonLoading,"height":"600","type":"table"}},[_c('v-data-table',{staticClass:"work-list-id-page",staticStyle:{"margin-top":"-20px"},attrs:{"item-key":"name","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totals,"footer-props":_vm.tableFooterProps,"item-class":_vm.colorSelectedRow},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.searchListAnalysis,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([{key:"item.analysisID",fn:function(ref){
var item = ref.item;
return [_c('a',{domProps:{"innerHTML":_vm._s(item.analysisTitleStr)},on:{"click":function($event){return _vm.viewOriginalFile(item.analysisID)}}})]}},{key:"item.analysisName",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openPopupFlowDesginer(item)}}},[_vm._v(_vm._s(item.analysisName))])]}},{key:"item.patientName",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewPatientInfo(item.patientID)}}},[_vm._v(_vm._s(item.patientName))])]}},{key:"item.results",fn:function(ref){
var item = ref.item;
return [_c('AnalysisHeatmap',{attrs:{"jsonResults":item.jsonResults,"blocks":80}})]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.dateCreatedStr)}})]}},{key:"item.statusID",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:{
            dark: item.statusID === -1,
            danger: item.statusID == 0,
            waiting: item.statusID === 1,
            'in-progress': item.statusID === 2,
            complete: item.statusID === 3,
          }},[_vm._v(" "+_vm._s(_vm.analysisStatusOptions.find(function (o) { return o.value === item.statusID; }).text)+" ")])]}},{key:"item.jsonResults",fn:function(ref){
          var item = ref.item;
return [_c('AnalysisProgress',{attrs:{"analysis":item},on:{"onViewResult":_vm.handleViewAnalysisResult,"onProgressUpdate":_vm.handleProgressUpdate}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAnalysis(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)})],1),_c('PatientProfilePopup',{ref:"PatientProfilePopup"}),_c('PopupCreateAnalysis',{ref:"PopupCreateAnalysis",on:{"onCreateAnalysis":_vm.handleCreateAnalysisSuccess}}),_c('DialogConfirm',{ref:"DialogConfirm"}),_c('AnalysisViewer',{ref:"AnalysisViewer"}),_c('PopupFlowInspector',{ref:"PopupFlowInspector"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }