<template>
  <div>
    <span
      v-if="isPlaintext && floatingMetadata"
      class="d-block analysis-plaintext-floating"
    >
      <span v-html="metadataText"></span>
    </span>
    <span
      v-if="isPlaintext && !floatingMetadata"
      class="d-block analysis-plaintext-container"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" v-html="metadataText"></span>
        </template>
        <span v-html="metadataText"></span>
      </v-tooltip>
    </span>
    <span class="d-block analysis-heatmap-container" v-if="isHeatmap">
      <v-tooltip v-for="i in numBlocks" :key="i" top>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="block"
            :style="{
              width: `calc(${100 / numBlocks}%)`,
            }"
            :class="{
              'grey lighten-2': series[i - 1] == 0,
              'amber lighten-2': series[i - 1] == 1,
              'orange lighten-2': series[i - 1] == 2,
              'grey lighten-2': !series[i - 1],
            }"
            @click="onBlockClick(i)"
          ></div>
        </template>
        <span v-html="className[series[i - 1]]"></span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import { calculateAverage } from "@/plugins/helper";
export default {
  props: {
    floatingMetadata: {
      type: Boolean,
      default: false,
    },
    blocks: {
      type: Number,
      default: 7,
    },
    jsonResults: {
      type: String,
      default: "{}",
    },
  },
  watch: {
    jsonResults() {
      this.validateAndRender();
    },
  },
  created() {
    this.validateAndRender();
  },
  data() {
    return {
      series: [],
      numBlocks: this.blocks,
      className: ["No TBUP", "Others", "TBUP"],
      isHeatmap: false,
      isPlaintext: false,
      metadataText: "",
      analysisResult: {},
    };
  },
  methods: {
    validateAndRender() {
      if (!this.jsonResults) return;
      var results = this.jsonResults;
      if (typeof results == "string") results = JSON.parse(results);
      this.analysisResult = results;

      this.resetData();

      var { predictions, metadatas } = results;
      if (predictions && predictions.length) {
        this.isHeatmap = true;
        this.renderHeatmap();
      }
      if (metadatas) {
        this.isPlaintext = true;
        this.renderMetadata();
      }
    },
    onBlockClick(i) {
      this.$emit("click", { click: i + 1, totals: this.numBlocks });
    },
    async renderHeatmap() {
      if (!this.blocks) return;
      this.series = [];
      var blocks = this.blocks;
      var { predictions } = this.analysisResult;
      if (!predictions || !predictions.length) return;
      if (!Array.isArray(predictions)) {
        predictions = predictions.split(",").map((i) => parseInt(i));
      }
      if (predictions.length < blocks) {
        blocks = predictions.length;
        this.numBlocks = blocks;
      }
      var stepSkip = Math.ceil(predictions.length / blocks);
      for (var i = 0; i < blocks; i++) {
        var sections = predictions.splice(0, stepSkip);
        var label = Math.round(calculateAverage(sections));
        this.series.push(label);
      }
      this.$forceUpdate();
    },
    renderMetadata() {
      var { metadatas } = this.analysisResult;
      if (!metadatas) return;
      if (typeof metadatas == "string") metadatas = JSON.parse(metadatas);
      var metadataPipes = [];

      Object.keys(metadatas).forEach((metaName) => {
        if (metaName == "RetinalLesionDetector") return;
        var value = metadatas[metaName].value || "No metadata";
        if (value.includes("|")) {
          value = value.split("|")[0];
        }
        metadataPipes.push({
          title: metaName
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace("Classifier", ""),
          value,
        });
      });

      if (this.series.length) {
        var value = "No TBUP";
        if (this.series.find((s) => s == 2)) {
          value = "TBUP found";
        } else if (this.series.find((s) => s == 1)) {
          value = "Others";
        }
        metadataPipes.push({
          title: "Dry Eye Type",
          value,
        });
      }

      this.metadataText = metadataPipes
        .map(
          (pipe) => `
          <label class="text-capitalize">» ${pipe.title}: </label>
          <b class="text-capitalize">${pipe.value}</b>`
        )
        .join("<br/>");
    },
    resetData() {
      this.series = [];
      this.metadataText = "";
      this.isHeatmap = false;
      this.isPlaintext = false;
    },
  },
};
</script>

<style lang="scss">
.analysis-heatmap-container {
  height: 20px;
  div.block {
    float: left;
    display: inline;
    height: 19px;
    width: 19px;
    background-color: #e0e0e0;
    cursor: pointer;
  }
}
.analysis-plaintext-container {
  cursor: pointer;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
}
.analysis-plaintext-floating {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 500px;
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
  color: #bdbdbd;
  padding: 15px 20px 15px 25px;
  border-radius: 10px;
  border: 2px solid #bdbdbd;
}
</style>

