<template>
  <div class="pt-4">
    <v-row class="pl-2 pr-2 pt-2">
      <v-col cols sm="6" md="4">
        <AnalysisMethodOptions
          v-model="analysisName"
          @input="searchListAnalysis"
        />
      </v-col>
      <v-col cols sm="6" md="2">
        <AnalysisStatusOptions v-model="statusID" @input="searchListAnalysis" />
      </v-col>
      <v-col cols>
        <AnalysisPatientOptions
          v-model="patientID"
          @input="searchListAnalysis"
        />
      </v-col>
      <v-col cols sm="2" md="1">
        <v-btn
          @click="openPopupCreateAnalysis"
          color="primary"
          style="margin: 2px 0px 0px -10px; width: 100%"
        >
          <v-icon class="mr-1" small>mdi-plus</v-icon>
          {{ $t("flow.CreateBtn") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader :loading="isSkeletonLoading" height="600" type="table">
      <v-data-table
        class="work-list-id-page"
        item-key="name"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totals"
        :footer-props="tableFooterProps"
        :item-class="colorSelectedRow"
        @update:items-per-page="searchListAnalysis"
        @update:page="onChangePagination"
        style="margin-top: -20px"
      >
        <template v-slot:[`item.analysisID`]="{ item }">
          <a
            @click="viewOriginalFile(item.analysisID)"
            v-html="item.analysisTitleStr"
          ></a>
        </template>

        <template v-slot:[`item.analysisName`]="{ item }">
          <a @click="openPopupFlowDesginer(item)">{{ item.analysisName }}</a>
        </template>

        <template v-slot:[`item.patientName`]="{ item }">
          <a @click="viewPatientInfo(item.patientID)">{{ item.patientName }}</a>
        </template>
        <template v-slot:[`item.results`]="{ item }">
          <AnalysisHeatmap :jsonResults="item.jsonResults" :blocks="80" />
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          <span v-html="item.dateCreatedStr"></span>
        </template>

        <template v-slot:[`item.statusID`]="{ item }">
          <v-chip
            :class="{
              dark: item.statusID === -1,
              danger: item.statusID == 0,
              waiting: item.statusID === 1,
              'in-progress': item.statusID === 2,
              complete: item.statusID === 3,
            }"
          >
            {{
              analysisStatusOptions.find((o) => o.value === item.statusID).text
            }}
          </v-chip>
        </template>

        <template v-slot:[`item.jsonResults`]="{ item }">
          <AnalysisProgress
            :analysis="item"
            @onViewResult="handleViewAnalysisResult"
            @onProgressUpdate="handleProgressUpdate"
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="deleteAnalysis(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-skeleton-loader>

    <PatientProfilePopup ref="PatientProfilePopup" />

    <PopupCreateAnalysis
      ref="PopupCreateAnalysis"
      @onCreateAnalysis="handleCreateAnalysisSuccess"
    />
    <DialogConfirm ref="DialogConfirm" />
    <AnalysisViewer ref="AnalysisViewer" />
    <PopupFlowInspector ref="PopupFlowInspector" />
  </div>
</template>

<script>
import { TABLE_FOOTER_PROPS } from "@/plugins/constant";
import { AnalysisStatusType } from "@/plugins/constant";
import AnalysisService from "./services/analysis";
import moment from "moment";
import i18n from "@/plugins/i18n";
import PatientProfilePopup from "@/components/common/Patient/components/PatientProfilePopup.vue";
import AnalysisMethodOptions from "./components/analysis/AnalysisMethodOptions";
import AnalysisStatusOptions from "./components/analysis/AnalysisStatusOptions";
import AnalysisPatientOptions from "./components/analysis/AnalysisPatientOptions";
import AnalysisProgress from "./components/analysis/AnalysisProgress";
import AnalysisHeatmap from "./components/analysis/AnalysisHeatmap";
import DialogConfirm from "@/components/DialogConfirm";
import AnalysisViewer from "./components/analysis/AnalysisViewer";
import PopupCreateAnalysis from "./components/analysis/PopupCreateAnalysis";
import PopupFlowInspector from "./PopupFlowInspector";
import { getAllFlows } from "./services/task";

const HEADER_CONFIGS = [
  {
    text: i18n.t("analysis.AnalysisID"),
    align: "start",
    sortable: false,
    value: "analysisID",
  },
  {
    text: i18n.t("analysis.AnalysisName"),
    align: "start",
    sortable: false,
    value: "analysisName",
  },
  {
    text: i18n.t("analysis.Result"),
    align: "start",
    sortable: false,
    value: "results",
    width: "240px",
  },
  {
    text: i18n.t("analysis.PatientName"),
    align: "start",
    sortable: false,
    value: "patientName",
    width: "180px",
  },
  {
    text: i18n.t("analysis.StatusID"),
    align: "center",
    sortable: false,
    value: "statusID",
    width: "120px",
  },
  {
    text: i18n.t("analysis.DateCreated"),
    align: "center",
    sortable: false,
    value: "dateCreated",
    width: "120px",
  },
  {
    text: i18n.t("analysis.Progress"),
    align: "center",
    sortable: false,
    value: "jsonResults",
    width: "160px",
  },
  {
    text: i18n.t("analysis.Actions"),
    align: "center",
    sortable: false,
    value: "actions",
    width: "50px",
  },
];
export default {
  components: {
    PatientProfilePopup,
    AnalysisMethodOptions,
    AnalysisStatusOptions,
    AnalysisPatientOptions,
    AnalysisProgress,
    PopupCreateAnalysis,
    AnalysisHeatmap,
    DialogConfirm,
    AnalysisViewer,
    PopupFlowInspector,
  },
  data() {
    return {
      isSkeletonLoading: true,
      selectedAnalysisID: null,
      patientID: null,
      statusID: AnalysisStatusType.All,
      analysisName: null,
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      totals: 0,
      items: [],
      headers: HEADER_CONFIGS,
      tableFooterProps: TABLE_FOOTER_PROPS,
      patientOptions: [],
    };
  },
  computed: {
    analysisStatusOptions() {
      return Object.keys(AnalysisStatusType).map((key) => ({
        value: AnalysisStatusType[key],
        text: key,
      }));
    },
  },
  async mounted() {
    await this.getDefaultAnalysisName();
    if (this.$route.params.patientId) {
      this.patientID = parseInt(this.$route.params.patientId);
    }
    if (this.$route.query.statusID) {
      this.statusID = parseInt(this.$route.query.statusID);
    }
    this.searchListAnalysis();
  },
  methods: {
    formatDate(str) {
      return str ? moment(str).format("DD-MM-YYYY") : "";
    },
    formatHour(str) {
      return str ? moment(str).format("HH:mm") : "";
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    viewPatientInfo(patientID) {
      this.$refs.PatientProfilePopup.openPopup(patientID);
    },
    viewOriginalFile(analysisID) {
      this.$refs.AnalysisViewer.open(analysisID, "original_path");
    },
    openPopupCreateAnalysis() {
      this.$refs.PopupCreateAnalysis.open(this.patientID, this.analysisName);
    },
    async getDefaultAnalysisName() {
      var result = await getAllFlows();
      if (!result || result.error) return;
      this.analysisName = result[0].title;
    },
    openPopupFlowDesginer(task) {
      var { analysisID, analysisName } = task;
      analysisName = encodeURI(analysisName);
      analysisID = encodeURI(analysisID);
      this.$refs.PopupFlowInspector.open(analysisName, analysisID);
    },
    handleViewAnalysisResult(analysisID) {
      var analysis = this.items.find((a) => a.analysisID == analysisID);
      if (!analysis) return;
      var { video_save_path, image_save_path } = JSON.parse(
        analysis.jsonResults
      );
      if (image_save_path) {
        return this.$refs.AnalysisViewer.open(analysisID, "image_save_path");
      }
      if (video_save_path) {
        return this.$refs.AnalysisViewer.open(analysisID, "video_save_path");
      }
    },
    handleProgressUpdate({ statusID, jsonResults, taskID }) {
      var ind = this.items.findIndex((a) => a.taskID == taskID);
      if (ind == -1) return;
      this.items[ind].statusID = statusID;
      this.items[ind].jsonResults = jsonResults;
      this.items[ind].results = JSON.parse(jsonResults);
      this.items[ind] = this.transformItem(this.items[ind]);
      this.$forceUpdate();
    },
    handleCreateAnalysisSuccess({ analysisName, patientID }) {
      this.analysisName = analysisName;
      this.patientID = patientID;
      this.searchListAnalysis();
    },
    isFinisedTaskStatus(statusID) {
      return [
        AnalysisStatusType.Success,
        AnalysisStatusType.Failed,
        AnalysisStatusType.Deleted,
      ].includes(statusID);
    },
    async deleteAnalysis({ analysisID }) {
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      await AnalysisService.deleteAnalysis(analysisID);
      this.searchListAnalysis();
    },
    async searchListAnalysis() {
      this.isSkeletonLoading = true;
      let statusID = this.statusID || "";
      let patientID = this.patientID || "";
      let analysisName = this.analysisName || "";

      if (!patientID) {
        this.items = [];
        this.totals = 0;
        this.isSkeletonLoading = false;
        return;
      }

      let { page, itemsPerPage } = this.options;

      let apiResult = await AnalysisService.searchAnalysis(
        analysisName,
        patientID,
        statusID,
        page,
        itemsPerPage
      );
      this.isSkeletonLoading = false;
      if (apiResult.error) {
        return this.$toast.error(
          "Cannot get list analysis at the moment. Please try again later!"
        );
      }
      var { items, totals } = apiResult;
      this.totals = totals;
      this.items = this.transformItems(items);
    },
    transformItems(items) {
      items.forEach((item) => {
        this.transformItem(item);
      });
      return items;
    },
    transformItem(item) {
      item.results = JSON.parse(item.jsonResults);
      item.analysisTitleStr = `
          <span class="d-block fs13">
            <b>${item.analysisID}</b>
          </span>
          <span class="d-block fs12">
            ${item.results.original_path || "Analyzing..."}
          </span>`;
      item.dateCreatedStr = `
          <span class="d-block text-center fs12">
            ${this.formatDate(item.dateCreated)}
          </span>
          <span class="d-block text-center fs10">
            ${this.formatHour(item.dateCreated)}
          </span>`;
      return item;
    },
    async onChangePagination(value) {
      this.options.page = value;
      await this.searchListAnalysis();
    },
    colorSelectedRow(item) {
      return item.analysisID == this.selectedAnalysisID ? "blue lighten-5" : "";
    },
  },
};
</script>

<style lang="scss">
.work-list-id-page {
  margin-top: 24px;
}
.v-chip:before {
  pointer-events: all;
  cursor: pointer;
}
.chief-text {
  max-width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
}
.fs14 {
  font-size: 14px;
}
.fs13 {
  font-size: 13px;
}
.fs12 {
  font-size: 12px;
}
.fs10 {
  font-size: 10px;
}
</style>