<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="dialog"
    max-width="800px"
    eager
  >
    <v-card class="pb-2">
      <v-card-title> {{ $t("analysis.CreateNewAnalysis") }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols sm="6" md="4">
            <AnalysisMethodOptions
              ref="AnalysisMethodOptions"
              v-model="analysisName"
              @input="onChangeAnalysisMethod"
            />
          </v-col>
          <v-col cols>
            <AnalysisPatientOptions v-model="patientID" />
          </v-col>
        </v-row>
        <FileDropZone
          ref="FileDropZone"
          :multiple="true"
          v-model="fileUploads"
        />
      </v-card-text>
      <v-card-actions style="margin-top: -10px">
        <v-btn @click="closeDialog" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="isProcessing"
          @click.stop="submit"
          class="primary mr-2 pl-5 pr-6"
        >
          <v-icon small id="upload-button" class="mr-2">mdi-upload</v-icon>
          {{ $t("flow.SaveBtn") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileDropZone from "@/components/FileDropZone";
import AnalysisMethodOptions from "./AnalysisMethodOptions";
import AnalysisPatientOptions from "./AnalysisPatientOptions";
import AnalysisService from "../../services/analysis";
import { sleep } from "@/plugins/helper";
import { executeSchema } from "../../services/task";

export default {
  components: {
    FileDropZone,
    AnalysisMethodOptions,
    AnalysisPatientOptions,
  },
  data() {
    return {
      dialog: false,
      fileUploads: [],
      analysisName: null,
      patientID: null,
      isProcessing: false,
    };
  },
  computed: {
    extensions() {
      if (this.analysisName == "Dry Eye - Video Analysis")
        return ["mp4", "webm"];
      else return ["png", "jpg", "jpq", "jpeg"];
    },
  },
  async mounted() {
    if (this.$route.params.patientId) {
      this.patientID = parseInt(this.$route.params.patientId);
    }
    if (this.$route.query.analysisName) {
      this.analysisName = this.$route.query.analysisName;
    }
  },
  methods: {
    open(patientID = null, analysisName = null) {
      if (patientID) {
        this.patientID = parseInt(patientID);
      }
      if (analysisName) {
        this.analysisName = analysisName;
      }
      this.dialog = true;
      this.$refs.FileDropZone.clean();
    },
    async submit() {
      this.isProcessing = true;
      try {
        for (var file of this.fileUploads) {
          await this.createAnalysis(file);
          this.$toast.success(
            `Successfully create analysis for file ${file.name}`
          );
          await sleep(1200);
        }
      } catch (err) {
        this.$toast.error(err.message);
        return;
      }
      this.isProcessing = false;
      var response = {
        analysisName: this.analysisName,
        patientID: this.patientID,
      };
      this.$emit("onCreateAnalysis", response);
      this.closeDialog();
    },
    async createAnalysis(file) {
      // 1. 1st, call API create AI-functions to get taskID
      // var result = await executeFlow(file, this.analysisName);
      var schema = this.$refs.AnalysisMethodOptions.flows.find(
        (f) => f.title == this.analysisName
      );
      if (!schema)
        return this.$toast.error("Cannot execute blank schema analaysis.");
      var result = await executeSchema(file, schema);
      if (!result || result.error) {
        this.$toast.error(`Error: ${result && result.error}`);
        return;
      }
      var { taskID, jsonResults } = result;
      jsonResults = jsonResults || "{}";
      // 2. Get %TaskID and send request into Analysis API
      var episodeID = this.$route.params.episodeID
        ? parseInt(this.$route.params.episodeID)
        : null;
      var episodeRecordID = this.$route.params.episodeRecordID
        ? parseInt(this.$route.params.episodeRecordID)
        : null;
      var orderID = this.$route.query.orderID
        ? parseInt(this.$route.query.orderID)
        : null;
      var body = {
        taskID,
        episodeID,
        episodeRecordID,
        orderID,
        jsonResults,
        analysisName: this.analysisName,
        patientID: this.patientID,
      };
      return await AnalysisService.createAnalysis(body);
    },
    onChangeAnalysisMethod() {
      this.fileUploads = [];
      this.$refs.FileDropZone.clean();
    },
    closeDialog() {
      this.$refs.FileDropZone.clean();
      this.fileUploads = [];
      this.dialog = false;
    },
  },
};
</script>