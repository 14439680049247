<template>
  <v-col cols="12" md="12" class="pt-0 mt-0">
    <v-row>
      <v-col cols="3" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.idSearch"
          append-icon="mdi-key"
          :label="$t('main.patient.searchPatientOptions.pID')"
          dense
          outlined
          @keyup.enter="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="3" class="pt-0 mt-0">
        <v-text-field
          v-model="value.aidSearch"
          :label="$t('personalInfo.aid')"
          dense
          outlined
          @keyup.enter="changeSearchOptions"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6">
      </v-col>
    </v-row>
    <hr style="margin-top:-20px; opacity: 0.3;" class="mb-10">
    <v-row>
      <v-col cols="12" md="3" class="pt-0 mt-0">
        <v-row>
          <v-col cols="12" md="12" class="pt-0 mt-0">
            <v-text-field
              v-model="value.keySearch"
              ref="keySearch"
              append-icon="mdi-magnify"
              :label="$t('main.patient.searchPatientOptions.searchName')"
              dense
              outlined
              @keyup.enter="changeSearchOptions"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="9" class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" md="4" class="pt-0 mt-0">
          <v-text-field
            v-model="value.dobSearch"
            :label="$t('main.patient.searchPatientOptions.DOB')"
            dense
            outlined
            @change="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mt-0">
          <v-text-field
            v-model="value.addressSearch"
            :label="$t('personalInfo.address')"
            dense
            outlined
            @change="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0 mt-0">
          <v-text-field
            v-model="value.phoneSearch"
            ref="keySearch"
            :label="$t('main.patient.searchPatientOptions.phone')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    </v-row>
  </v-col>
  <!-- <v-row>
    <v-col cols="12" md="3" class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="value.keySearch"
            ref="keySearch"
            append-icon="mdi-magnify"
            :label="$t('main.patient.searchPatientOptions.searchName')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="9" class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.idSearch"
            :label="$t('main.patient.searchPatientOptions.pID')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.aidSearch"
            label="AID"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.dobSearch"
            :label="$t('main.patient.searchPatientOptions.DOB')"
            dense
            outlined
            @change="changeSearchOptions"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="value.phoneSearch"
            ref="keySearch"
            :label="$t('main.patient.searchPatientOptions.phone')"
            dense
            outlined
            @keyup.enter="changeSearchOptions"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row> -->
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          keySearch: "",
          idSearch: "",
          aidSearch: "",
          phoneSearch: "",
          dobSearch: "",
          addressSearch: "",
        };
      },
    },
  },
  components: {},
  computed: {},
  data: () => {
    return {};
  },
  async mounted() {},
  methods: {
    changeSearchOptions() {
      this.$emit("input", this.value);
    },
  },
};
</script>