<template>
  <v-dialog v-model="isShow" max-width="1360px" eager>
    <v-card color="black" min-height="500px" height="auto" class="pb-4 pt-2">
      <v-card-title class="justify-center ma-0 pa-0" id="dry-eye-container">
        <img v-if="isImage" :src="url" height="500px" class="mw-100" />
        <video
          id="dry-eye-video-player"
          v-if="isVideo"
          controls
          height="500px"
          :src="url"
          class="mw-100"
        ></video>
      </v-card-title>
      <v-card-title class="justify-center ma-0 pa-0" v-if="jsonResults">
        <span
          id="dry-eye-heatmap"
          class="d-block w-100"
          style="padding: 15px 15px 0px 15px"
        >
          <AnalysisHeatmap
            :jsonResults="jsonResults"
            :floatingMetadata="true"
            :blocks="80"
            @click="onBlockClick"
          />
        </span>
      </v-card-title>
    </v-card>
    <DialogConfirm ref="DialogConfirm" :message="message" />
  </v-dialog>
</template>

<script>
import AnalysisService from "../../services/analysis";
import { getStreamUrl } from "../../services/task";
import DialogConfirm from "@/components/DialogConfirm";
import i18n from "@/plugins/i18n";
import AnalysisHeatmap from "./AnalysisHeatmap";

export default {
  components: {
    DialogConfirm,
    AnalysisHeatmap,
  },
  data() {
    return {
      url: "",
      jsonResults: "",
      message: "",
      isShow: false,
      imagesExtensions: ["jpq", "jpg", "jpeg", "png", "7bit"],
      videoExtensions: ["mp4", "webm"],
    };
  },
  computed: {
    isImage() {
      if (!this.url) return false;
      var ext = this.url.split(".").pop();
      return this.imagesExtensions.includes(ext);
    },
    isVideo() {
      if (!this.url) return false;
      var ext = this.url.split(".").pop();
      return this.videoExtensions.includes(ext);
    },
  },
  methods: {
    onBlockClick({ click, totals }) {
      if (!this.isVideo) return;
      var media = document.getElementById("dry-eye-video-player");
      var duration = media.duration;
      media.currentTime = (click / totals) * duration;
    },
    async open(analysisID, fieldName = "original_path") {
      if (!analysisID) return;
      this.isShow = true;
      var analysis = await AnalysisService.getAnalysisDetail(analysisID);
      if (!analysis || !analysis.jsonResults) {
        this.message = i18n.t("analysis.ErrorDueToEmptyUrl");
        await this.$refs.DialogConfirm.confirm();
        return;
      }
      this.jsonResults = analysis.jsonResults;
      var jsonResults = JSON.parse(analysis.jsonResults);
      if (jsonResults.error) {
        this.message = i18n.t("analysis.ErrorOccuredDuringAnalysis");
        await this.$refs.DialogConfirm.confirm();
        return;
      }
      var filePath = jsonResults[fieldName];
      this.url = getStreamUrl(filePath);
      this.$forceUpdate();
      // this.updateHeatmapWidth();
    },
    updateHeatmapWidth() {
      if (!this.isVideo) return;
      var video = document.getElementById("dry-eye-video-player");
      if (!video) return;
      video.addEventListener(
        "loadedmetadata",
        function () {
          document.getElementById(
            "dry-eye-heatmap"
          ).style.width = `${this.videoWidth}px`;
        },
        false
      );
    },
  },
};
</script>

<style lang="scss">
.fw {
  width: 100%;
}
.mw-100 {
  max-width: 100%;
}
.w-50 {
  width: 50%;
}
</style>