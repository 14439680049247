<template>
  <div>
    <v-select
      :label="$t('analysis.PatientOptions')"
      :items="patientOptions"
      v-model="patientID"
      outlined
      dense
      @click="openPopupSearch"
      hide-no-data
      hide-details
    >
    </v-select>
    <PopupSearchingPatient
      ref="PopupSearchingPatient"
      @onPatientSelected="onPatientSelected"
    />
  </div>
</template>

<script>
import PopupSearchingPatient from "@/components/common/Patient/PopupSearchingPatient";
import PatientService from "@/services/patient";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  components: {
    PopupSearchingPatient,
  },
  data() {
    return {
      patientID: this.value || null,
      patientOptions: [],
    };
  },
  watch: {
    value() {
      this.patientID = this.value;
      this.getPatientDetail();
    },
  },
  methods: {
    openPopupSearch() {
      this.$refs.PopupSearchingPatient.open();
    },
    onPatientSelected({ patientID, fullName }) {
      this.patientOptions = [
        {
          text: fullName,
          value: patientID,
        },
      ];
      this.patientID = patientID;
      this.$emit("input", patientID);
    },
    async getPatientDetail() {
      var patientID = this.patientID;
      var result = await PatientService.getPatientDetail(patientID);
      if (!result || result.error) return;
      var { personalInfo } = result;
      var { fullName } = personalInfo;
      this.onPatientSelected({ patientID, fullName });
    },
  },
};
</script>