<template>
  <!-- eslint-disable -->
  <v-dialog v-model="isShow" max-width="1600px" eager>
    <v-card height="800px" width="1600px">
      <v-card-subtitle style="padding: 0px">
        <iframe :src="url" height="794px" class="iframe-flow-designer" />
      </v-card-subtitle>
    </v-card>
  </v-dialog>
</template>

<script>
import { sleep } from "@/plugins/helper";
export default {
  components: {},
  data: function () {
    return {
      url: "",
      isShow: false,
    };
  },
  methods: {
    async open(flowTitle, analysisID = "") {
      this.url = `/inspector?title=${flowTitle}&analysisID=${analysisID}&tick=${Math.random()}`;
      await sleep(50);
      this.isShow = true;
    },
  },
};
</script>
<style lang="scss">
iframe.iframe-flow-designer {
  outline: none !important;
  width: 100% !important;
  min-height: 500px !important;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #bdbdbd !important;
  border-radius: 3px !important;
}
</style>